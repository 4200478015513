import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Dexposure from './dexposure';
import './font.css';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Fragment = makeShortcode("Fragment");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Random things`}</h1>
    <h2>{`1. Succession art cover`}</h2>
    <Fragment mdxType="Fragment">
  <div {...props} style={{
        backgroundImage: 'url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2FgW4ckooQngA%2Fmaxresdefault.jpg&f=1&nofb=1)',
        backgroundImageRepeat: 'repeat-x',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: '17%',
        filter: 'blur(0px)',
        fontFamily: 'Succession',
        fontSize: '100px',
        fontStyle: 'normal',
        fontWeight: '900',
        letterSpacing: '0.4em',
        lineHeight: 1,
        mixBlendMode: 'none',
        opacity: 1,
        paddingBottom: '0px',
        paddingLeft: '10px',
        paddingTop: '0px',
        paddingRight: '30px',
        textTransform: 'uppercase',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      }}>
    Succession
  </div>
  <small style={{
        marginLeft: '50px'
      }}>
    Family, fortune, envy, jealousy, privilege, passed on legacy, secret,
    sabotage, borderline, felony, suicide, subtract, selfish, pedigree
  </small>
    </Fragment>
    <h2>{`2. Generative draft. photo in the center for unknown artist`}</h2>
    <Fragment mdxType="Fragment">
  <Dexposure mdxType="Dexposure" />
    </Fragment>
    <h2>{`4. Another Story`}</h2>
    <Fragment mdxType="Fragment">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Major+Mono+Display:wght@400" />
  <div {...props} sx={{
        backgroundImage: 'url(https://user.fm/files/v2-8920f0d1daeaae4314ae9a000a383567/Abu-Aqleh_Shereen.jpg)',
        backgroundImageRepeat: 'repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'bottom',
        backgroundSize: '64px',
        filter: 'blur(0px)',
        fontFamily: 'Major Mono Display',
        fontSize: '80px',
        fontStyle: 'normal',
        fontWeight: '900',
        letterSpacing: '0.08em',
        lineHeight: 1.62,
        mixBlendMode: 'none',
        opacity: 1,
        paddingBottom: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingRight: '0px',
        textTransform: 'uppercase',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'rgba(255, 19, 19, 0.51)'
      }}>
    Shereen Abu-Aqleh
  </div>
    </Fragment>
    <p>{`"Born in Jerusalem, Shereen Abu-Aqleh began her career as a reporter after graduating with a Bachelor degree in journalism from Yarmouk University in Jordan. From 1994 through 1997, Shereen worked as a journalist for the official Palestinian radio station, "Sawt Filistine," after which she began working as a television correspondent with the satellite news station "Aljazeera." Working in the occupied Palestinian territories during the recent Intifada pushed Shereen into a demanding career path. Since that time, through Shereen's hard work and commitment, she has become one of the most reliable and known reporters in the region.`}</p>
    <p>{`Her colleagues describe her as a serious and calm reporter, always determined to verify all of the information that she presents. The fact that "Aljazeera" has become the most watched Arabic language television station in the world increases Shereen's feeling of responsibility to present the facts in a professional and responsible manner.`}</p>
    <p>{`Those who know Shereen on a more personal level find that she is a softhearted human being that feels the pain of her fellow Palestinians. Several times she has had to report in dangerous areas where heavy clashes made conditions unbearable, yet she always remained calm and maintained her composure in front of the camera. Her ability of dealing with extremely stressful situations, suppressing the natural fear that she feels, is remarkable. Her deter-mination to show the truth has led her to constantly consult with fellow reporters and seek information from many different sources and contacts.`}</p>
    <p>{`Wherever she goes in Palestine or the Arab world, people recognize her and point her out, oftentimes approaching her to express their admiration and appreciation for her work.`}</p>
    <p>{`Becoming famous so quickly and the demands of being a reporter in the region, has cost her much of her privacy and private life. When asked about her dreams she answered, " I would like to have time to read, play the piano and travel. It seems to me that I have had to postpone my private life." Yet she also believes that the price is worth it. Shereen is a woman with a mission: to tell the world about the real facts of the Israeli aggressions against the Palestinians."`}</p>
    <small style={{
      marginTop: '-100px'
    }}>
  __{' '}
  <a href="http://www.webgaza.net/palestine/people_profiles/Abu-Aqleh_Shereen.htm">
    http://www.webgaza.net/palestine/people_profiles/Abu-Aqleh_Shereen.htm
  </a>
    </small>
    <p>{`"On 11 May 2022, the Palestinian Health Ministry announced that Abu Akleh had been shot and killed by the Israel Defense Forces (IDF) while reporting on IDF raids in Jenin."`}</p>
    <small style={{
      marginTop: '-100px'
    }}>
  __ <a href="https://en.wikipedia.org/wiki/Shireen_Abu_Akleh">Wikipedia</a>
    </small>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      